<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                	<!-- 框 -->
                    <v-alert :dismissible="false" class="lime black--text" dark border="left" icon="fab fa-artstation black--text">
                    	<v-row no-gutters class="align-center mt-n1 mb-n1">
                            <v-col cols="10">
                                <span>Exchanges</span>
                                <span class="ml-5">注: 启用后可以进行交易，关闭后会禁止该交易所的下单/编辑/查询等相关操作，请谨慎操作！</span>
                            </v-col>
                            <v-col cols="2" class="text-right">
                                <v-btn tile small outlined color="black" @click="showAddDialog">New</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
				<v-col cols="12">
					<v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
					<!-- 表头 -->
					<v-data-table dense :headers="headers" :items="items" hide-default-footer v-if="!loading">
						<!-- 表数据 -->
						<template v-slot:body="{ item }">
							<tbody>
								<tr v-for="(item, index) in items" :key="item.id">
									<td>{{ item.name }}</td>
									<td>{{ item.code }}</td>
									<td>{{ item.description }}</td>
									<td>{{ common.formatTime(item.timestamp) }}</td>
									<td>
										<v-btn icon class="ml-2" @click="">
											<v-switch color="lime" v-model="item.enabled" @change="updateEnabled(item.id)"></v-switch>
										</v-btn>
										<span class="ml-5">{{ item.enabled }}</span>
									</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
					<v-divider v-if="!loading"></v-divider>
				</v-col>
            </v-row>
        </v-container>
        <!-- 新增 -->
		<v-dialog v-model="addDialog" persistent max-width="500px">
		    <!-- 模态框 -->
		    <v-card>
		        <v-card-title>
		            <span class="fs-24">{{ addDialogTitle }}</span>
		            <v-spacer></v-spacer>
		            <v-btn @click="addDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
		        </v-card-title>
		        <v-card-text>
		            <v-container>
		                <v-form ref="addForm" v-model="addFormValid">
		                    <v-row>
		                        <v-col cols="12">
		                        	<v-text-field dense v-model="addForm.name" label="Name" required clearable></v-text-field>
		                        </v-col>
		                        <v-col cols="12">
		                        	<v-text-field dense v-model="addForm.description" label="Description" required clearable></v-text-field>
		                        </v-col>
		                    </v-row>
		                </v-form>
		            </v-container>
		        </v-card-text>
		        <v-card-actions>
		            <v-spacer></v-spacer>
		            <v-btn v-if="addDialogTitle == 'New'" class="lime--text" text @click="add" :disabled="!addFormValid" :loading="addLoading">Save</v-btn>
		        </v-card-actions>
		    </v-card>
		</v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers: [
                    { text: 'Name', value: 'name', sortable: false },
					{ text: 'Code', value: 'code', sortable: false },
					{ text: 'Description', value: 'description', sortable: false },
					{ text: 'Time', value: 'timestamp', sortable: true },
					{ text: 'Enabled', value: 'action', sortable: false }
                ],
                items: [],
                menu:false,
				indexNo:0,
				loading:false,
				addDialog: false,
				addDialogTitle: '',
				addLoading: false,
				addForm: {
					name: null,
					description: null
				},
            }
        },
        computed: {
            ...mapGetters(['user']),
            addFormValid:{
            	get(){
            		return this.addForm.name != null;
            	},
            	set(v){

            	}
            }
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
			this.getList();
        },
        created() {
           
        },
        methods: {
		   	// 获取数据
		   	getList() {
			   this.loading = true;
			   let api = config.baseUrl + '/exchange/public/getExchanges';
		       this.$http.get(api).then(response => {
	               if (response.data.code == 200) {
					   this.loading = false;
	                   this.items = response.data.result;
	               }
	           })
		   	},
		   // 添加
		   add(){
			   if (this.$refs.addForm.validate()) {
			       let api = config.baseUrl + '/exchange/private/addExchange';
				   this.addLoading = true;
			       this.$http.post(api, this.addForm).then(response => {
			           let data = response.data;
			           if (data.code == 200) {
			               this.$store.dispatch('snackbarMessageHandler', "Success");
						   this.addDialog = false;
			               this.getList();
						   this.$refs.addForm.reset();
						   this.addLoading = false;
			           } else {
			           		this.$store.dispatch('snackbarMessageHandler', data.message);
			           		this.$refs.addForm.reset();
			           		this.addLoading = false;
			           }
			       }, error => {
			           this.$store.dispatch('snackbarMessageHandler', "Error");
			           this.versionLoading = false;
			       });
			   }
		   },
		   // 显示新增弹窗
		   showAddDialog(){
				this.addDialogTitle = 'New';
				this.addForm = {
					project: null,
					version: null
				};
				this.addDialog = true;
		   },
		   // 更新是否启用
		   updateEnabled(id){
		   		let api = config.baseUrl + '/exchange/private/update/enabled?id=' + id;
		       	this.$http.put(api).then(response => {
		           let data = response.data;
		           if (data.code == 200) {
		               	this.$store.dispatch('snackbarMessageHandler', "Success");
		               	this.getList();
		           } else {
		           		this.$store.dispatch('snackbarMessageHandler', data.message);
		           }
		       	}, error => {
		           	this.$store.dispatch('snackbarMessageHandler', "Error");
		       	});
		   }
        }
    }
</script>

<style>

</style>